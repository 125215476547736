<template>
    <li v-show="group.featuredLink && group.featuredLink.url">
        <NuxtLink
            :to="getLinkUrl(group.featuredLink.url)"
            class="group/item link flex items-center gap-x-1 whitespace-nowrap pt-6 font-bold focus:outline-none lg:pt-0 lg:hover:underline"
            @click="emit('link-click')"
        >
            {{ group.featuredLink ? group.featuredLink.label : '' }}
            <Icon
                name="chevron-right"
                class="text-sm transition-all group-hover/item:translate-x-2"
            />
        </NuxtLink>
    </li>
    <li v-for="item in group.items" :key="item">
        <div v-show="item.type === 'menu_item'">
            <NuxtLink
                class="link flex items-center gap-x-2 whitespace-nowrap py-1.5 text-base focus:outline-none lg:hover:text-brew"
                :to="item.link ? getLinkUrl(item.link.url) : ''"
                @click="emit('link-click')"
            >
                <span v-if="item.label" class="lg:hover:underline">
                    {{ item.label }}
                </span>
                <span v-else class="lg:hover:underline">
                    {{ item.link ? item.link.label : '' }}
                </span>
                <span
                    v-show="item.isFeatured"
                    class="flex items-center text-vibe"
                >
                    <Icon name="left-accent-icon" class="text-xl" />
                    <span class="font-accent no-underline">New</span>
                    <Icon name="right-accent-icon" class="text-xl" />
                </span>
            </NuxtLink>
        </div>
        <div v-show="item.type !== 'menu_item'">
            <div
                class="mb-1 mt-5 whitespace-nowrap text-sm font-semibold uppercase tracking-wide text-grind-600"
            >
                {{ item.title }}
            </div>
        </div>
    </li>
</template>

<script setup lang="ts">
import { stripTrailingSlash } from '~/utils/helpers';

const emit = defineEmits(['link-click']);

defineProps({
    group: {
        type: Object,
        required: true,
    },
});

const getLinkUrl = (link: string) => {
    return stripTrailingSlash(link !== '/' ? `/${link}` : link);
};
</script>
