<template>
    <div class="relative z-[99999] w-full bg-grind py-4 lg:h-[51px]">
        <div
            class="container mx-auto flex h-full items-center justify-end gap-x-2 px-2 md:justify-between md:gap-x-4 md:px-4"
        >
            <SocialLinks class="hidden md:inline-flex" />
            <div
                v-if="announcements.length"
                class="flex shrink items-center justify-center overflow-hidden text-sm text-white md:gap-x-8"
            >
                <button
                    v-if="announcements && announcements.length > 1"
                    aria-label="Previous Announcement"
                    class="hidden rounded p-1.5 transition-all hover:bg-white hover:text-grind md:inline-flex"
                    @click="previous"
                >
                    <Icon name="chevron-left" />
                </button>
                <div
                    class="line-clamp-2 w-full overflow-visible px-2 leading-tight"
                >
                    <Transition name="slide" mode="out-in">
                        <div :key="currentIndex">
                            <div
                                v-show="announcements"
                                v-html="announcements[currentIndex]"
                            ></div>
                        </div>
                    </Transition>
                </div>
                <button
                    v-if="announcements && announcements.length > 1"
                    aria-label="Next Announcement"
                    class="inline-flex rounded p-1.5 transition-all hover:bg-white hover:text-grind"
                    @click="next"
                >
                    <Icon name="chevron-right" />
                </button>
            </div>

            <div
                class="relative flex flex-none items-center justify-between gap-x-2 md:gap-x-4"
            >
                <ProfileMenu />

                <NuxtLink
                    aria-label="Cart"
                    to="/cart"
                    class="relative rounded p-1.5 text-white hover:bg-white hover:text-grind md:text-lg"
                >
                    <Icon name="cart-shopping" />

                    <span
                        v-if="cartIconQuantity"
                        class="absolute right-0 top-0 -m-[1px] flex h-4 w-4 items-center justify-center rounded-full border-[0.5px] border-white bg-spice"
                    >
                        <span class="text-2xs font-bold text-grind">
                            {{ cartIconQuantity }}
                        </span>
                    </span>
                </NuxtLink>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import SocialLinks from '~/components/social/SocialLinks.vue';
import { richTextToHtml } from '~/utils/storyblok';
import cartIconQuantity from '~/state/CartIconQuantity';

const { data: serverAnnouncements } = await useFetch(
    `/nuxt-api/global/announcements`,
);

const announcements = computed<[]>(() => {
    return serverAnnouncements.value.flatMap((item: any) => {
        return richTextToHtml(item.content) as string;
    });
});

const currentIndex = ref(0);

const previous = () => {
    currentIndex.value -= 1;
    if (currentIndex.value < 0) {
        currentIndex.value = announcements.value.length - 1;
    }
};

const next = () => {
    currentIndex.value += 1;
    if (currentIndex.value >= announcements.value.length) {
        currentIndex.value = 0;
    }
};
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
    transition: all 0.25s ease-out;
}
.slide-enter-from {
    opacity: 0;
    transform: translateX(30px);
}

.slide-leave-to {
    opacity: 0;
    transform: translateX(-30px);
}

.line-clamp-2 :deep(p) {
    line-height: 0.9rem;
}
</style>
