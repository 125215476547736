<template>
    <TopBar />
    <header
        id="page-header"
        ref="headerRef"
        class="sticky top-0 z-[99998] flex-1 overflow-hidden bg-white shadow-lg lg:h-[100px] lg:flex-none lg:overflow-visible"
    >
        <div
            class="container flex flex-col items-center lg:mx-auto lg:h-full lg:flex-row lg:justify-between lg:gap-x-12"
        >
            <MobileMainMenu :data="data" />
            <MainMenu :data="data" />
        </div>
    </header>
</template>

<script setup lang="ts">
import {
    useLocalStructuredData,
    useOrganizationStructuredData,
} from '~/composables/useStructuredData';
import MainMenu from '~/layouts/includes/menu/MainMenu.vue';
import MobileMainMenu from '~/layouts/includes/menu/MobileMainMenu.vue';

const { data: data } = await useFetch(`/nuxt-api/global/header`);

const organizationStructuredData = useOrganizationStructuredData();

useServerHead(() => ({
    script: organizationStructuredData,
}));

const localStructuredData = useLocalStructuredData();

useServerHead(() => ({
    script: localStructuredData,
}));
</script>
