<template>
    <Menu v-slot="{ close }">
        <ClientOnly>
            <template #fallback>
                <a
                    href="#"
                    aria-label="Account"
                    class="mb-0 cursor-pointer rounded p-1.5 text-white hover:bg-white hover:text-grind md:text-lg"
                >
                    <Icon name="user-large" />
                </a>
            </template>
            <MenuButton as="template">
                <a
                    v-if="user && user.avatar"
                    aria-label="Account"
                    class="mb-0 cursor-pointer rounded p-1.5 text-white hover:bg-white hover:text-grind md:text-lg"
                    data-cy="profile-menu-toggle"
                >
                    <img
                        :src="user.avatar"
                        alt="Avatar"
                        class="h-6 w-6 rounded-full object-contain"
                    />
                </a>
                <a
                    v-else-if="user"
                    aria-label="Account"
                    href="#"
                    class="mb-0 cursor-pointer rounded p-1.5 text-white hover:bg-white hover:text-grind md:text-lg"
                    data-cy="profile-menu-toggle"
                >
                    <Icon name="user-large" />
                </a>
                <NuxtLink
                    v-else
                    aria-label="Login"
                    to="/login"
                    class="mb-0 cursor-pointer rounded p-1.5 text-white hover:bg-white hover:text-grind md:text-lg"
                >
                    <Icon name="user-large" />
                </NuxtLink>
            </MenuButton>
            <FadeTransition>
                <MenuItems
                    class="fixed left-0 right-0 top-12 mt-2 px-4 focus:outline-none md:absolute md:left-auto md:right-10 md:top-8 md:origin-top-right md:px-0"
                >
                    <ProfileCard
                        :name="user?.name"
                        :logo-url="user?.avatar"
                        :show-close="true"
                        @on-sign-out="close"
                        @close="close"
                    >
                        <template #intro>You are logged in as</template>
                        <template #content>
                            <div
                                class="gap-x-8 px-6 py-4 md:flex md:p-0 md:py-6"
                            >
                                <div>
                                    <div
                                        class="mb-1 whitespace-nowrap text-sm font-semibold uppercase tracking-wide text-[#6F7479]"
                                    >
                                        {{ user?.company.name }}
                                    </div>
                                    <nav>
                                        <MenuItem
                                            v-for="link in companyLinks"
                                            :key="link.href"
                                            as="ul"
                                            class="block"
                                        >
                                            <li>
                                                <div>
                                                    <a
                                                        :href="link.href"
                                                        class="flex items-center gap-x-2 whitespace-nowrap py-1.5 text-base hover:text-brew hover:underline"
                                                    >
                                                        {{ link.label }}
                                                    </a>
                                                </div>
                                            </li>
                                        </MenuItem>
                                    </nav>
                                </div>
                                <div class="pt-6 md:pt-0">
                                    <div
                                        class="mb-1 whitespace-nowrap text-sm font-semibold uppercase tracking-wide text-[#6F7479]"
                                    >
                                        My Account
                                    </div>
                                    <nav>
                                        <MenuItem
                                            v-for="link in accountLinks"
                                            :key="link.href"
                                            as="ul"
                                            class="block"
                                        >
                                            <li>
                                                <div>
                                                    <a
                                                        :href="link.href"
                                                        class="flex items-center gap-x-2 whitespace-nowrap py-1.5 text-base hover:text-brew hover:underline"
                                                    >
                                                        {{ link.label }}
                                                    </a>
                                                </div>
                                            </li>
                                        </MenuItem>
                                    </nav>
                                </div>
                            </div>
                        </template>
                    </ProfileCard>
                </MenuItems>
            </FadeTransition>
        </ClientOnly>
    </Menu>
</template>

<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import FadeTransition from '~/components/transitions/FadeTransition.vue';

const { user } = useAuth();

const companyLinks = computed(() => {
    if (!user.value) {
        return null;
    }

    return [
        {
            href: user.value.dashboardUrl,
            label: 'Dashboard',
        },
        {
            href: user.value.dashboardUrl + '/projects',
            label: 'Projects',
        },
        {
            href: user.value.dashboardUrl + '/order-history',
            label: 'Order History',
        },
        {
            href: user.value.dashboardUrl + '/addresses',
            label: 'Addresses',
        },
        {
            href: user.value.dashboardUrl + '/payment-methods',
            label: 'Payment Methods',
        },
        {
            href: user.value.dashboardUrl + '/info',
            label: 'Company Info',
        },
        {
            href: user.value.dashboardUrl + '/permissions',
            label: 'Users & Permissions',
        },
    ];
});

const accountLinks = computed(() => {
    if (!user.value) {
        return null;
    }

    return [
        {
            href: '/account/profile',
            label: 'Profile',
        },
        {
            href: '/account/companies',
            label: 'Companies',
        },
    ];
});
</script>
